import axios from "axios";
const AXIOS = axios.create({
 baseURL: "http://13.59.180.22:8080/CanberraService/",
  // baseURL: "http://192.168.0.101:8080/CanberaService/",

});

export default {
  data() {
    return {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      headersForm: {
        "Content-Type": "multipart/form-data",
      },
      post: "POST",
      get: "GET",
    };
  },

  // getAuthHeaders() {
  //   let apllicationId = JSON.parse(localStorage.getItem("userprofile"))[
  //     "application_id"
  //   ];
  //   let authkey = localStorage.getItem("authKey");
  //   let headers = {
  //     "Content-Type": "application/json",
  //     Authorization: "Bearer " + apllicationId + " " + authkey,
  //   };
  //   return headers;
  // },

  customerDetail(jsonObj) {
    return AXIOS.post(`/customer/customerDetails`, jsonObj, {
      headers: this.headers,
    });
  },
  customerPersonalDetail(jsonObj) {
    return AXIOS.post(`/customer/personalDetails`, jsonObj, {
      headers: this.headers,
    });
  },
  
  customerBankDetail(jsonObj) {
    return AXIOS.post(`/customer/bankDetails`, jsonObj, {
      headers: this.headers,
    });
  },
  

  getCustomerDetail(jsonObj) {
    return AXIOS.post(`/customer/getcustomerDetails`, jsonObj, {
      headers: this.headers,
    });
  },
  getpersonalDetail(jsonObj) {
    return AXIOS.post(`/customer/getpersonalDetails`, jsonObj, {
      headers: this.headers,
    });
  },
  getBankDetail(jsonObj) {
    return AXIOS.post(`/customer/getbankDetails`, jsonObj, {
      headers: this.headers,
    });
  },
  

  loanVehicleDetail(jsonObj) {
    return AXIOS.post(`/customer/vehicleDetails`, jsonObj, {
      headers: this.headers,
    });
  },
  loanAmountDetail(jsonObj) {
    return AXIOS.post(`/customer/loanDetails`, jsonObj, {
      headers: this.headers,
    });
  },
  
  

 
  getVehicleDetail(jsonObj) {
    return AXIOS.post(`/customer/getvehicleDetails`, jsonObj, {
      headers: this.headers,
    });
  },

 
  getifscCodeBankDetail(jsonObj) {
    return AXIOS.post(`/customer/getifscCode`, jsonObj, {
      headers: this.headers,
    });
  },
 
  getcustomerAllDetail(jsonObj) {
    return AXIOS.post(`/customer/getBranchCustomerDetails`, jsonObj, {
      headers: this.headers,
    });
  },
 
  getAreaList(jsonObj) {
    return AXIOS.get(`/branch/getAreaList`, jsonObj, {
      headers: this.headers,
    });
  },
  getBrokerList(jsonObj) {
    return AXIOS.get(`/branch/getBrokerList`, jsonObj, {
      headers: this.headers,
    });
  },
  getDealerList(jsonObj) {
    return AXIOS.get(`/branch/getAgentList`, jsonObj, {
      headers: this.headers,
    });
  },
  digilocker(jsonObj) {
    return AXIOS.post(`/digiLocker/getRedirectLink`, jsonObj, {
      headers: this.headers,
    });
  },
  getDigiLocker(jsonObj) {
    return AXIOS.post('/digiLocker/getDigiLockerData', jsonObj, {
      headers: this.headers,
    })
  },


/// LATEST SERVICES FOR CANBERRA PROJECT

  updateCustomerRecords(jsonObj) {
    return AXIOS.post('/customer/updateCustomerDetails', jsonObj, {
      headers: this.headers,
    })
  },

  getMasterData(jsonObj){
    return AXIOS.get('/masterData/getMasterList', jsonObj, {
      headers: this.headers
    })
  },

  saveLoadData(jsonObj){
    return AXIOS.post('/loan/saveLoanDetails', jsonObj,{
      headers: this.headers
    })
  },

  searchUsername(jsonObj){
    return AXIOS.post('/customer/getActiveCustomersList', jsonObj,{
      headers: this.headers
    })
  },

  saveDeductionCharges(jsonObj){
    return AXIOS.post('/loan/saveDeductionDetails', jsonObj,{
      headers: this.headers
    })
  },

  saveGuarantorData(jsonObj){
    return AXIOS.post('/loan/saveGuarantorDetails', jsonObj,{
      headers: this.headersForm
    })
  },

  getLoanData(jsonObj){
    return AXIOS.post('/loan/getLoanDetails', jsonObj,{
      headers: this.headers
    })
  }
};
